.description {
    color: #bcbcbc;
    text-align: center;
    font-size: 1.2em;
}

.modal-content {
    margin: 1em auto;
    background: #2b2b2b !important;
    padding: 0.5em;
}

.title {
    color: white;
}

.container-buttons-error {
    justify-content: center;
}
