.container-suggestion {
  display: flex;
  justify-content: space-between;
  transition: transform 1s;
  opacity: 0;

  &.close {
    opacity: 0;
  }
  &.open {
    opacity: 1;
  }

  p {
    margin: 0;
    padding: 0;
    &.description {
      color: #939393;
    }
  }
  .left {
    display: flex;
    justify-content: left;
    align-items: center;
    .left2 {
      margin-left: 10px;
      display: flex;
      align-items: left;
      flex-direction: column;
      justify-content: center;
    }
    div.image {
      border: 1px solid rgb(111, 111, 111);
      width: 32px;
      height: 32px;
      border-radius: 10px;
      background-size: cover;
      background-position: center center;
      background-repeat: 'no-repeat';
    }
  }
}

.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 800ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 800ms;
}

.container-suggest {
  width: 47%;

  .react-autosuggest__container {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .react-autosuggest__input {
    width: 100%;
    height: 40px;
    padding: 10px;
    border-radius: 4px;
    border: none;
    // background-color: #1e1e1e;
    color: #000;
    font-size: 16px;
  }

  .react-autosuggest__input:focus {
    outline: none;
  }

  #react-autowhatever-1 {
    width: 100%;
    max-height: 400px;
    overflow-y: auto !important;
  }
  .react-autosuggest__suggestions-container--open {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    max-height: 400px;
    overflow-y: auto !important;
    background-color: #2b2b2b;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transform: translateY(20%);
    transition: opacity 0.6s ease-in-out, visibility 0s linear 0.6s, transform 0.6s ease-in-out;
  }
  .react-autosuggest__suggestions-container--open.react-autosuggest__suggestions-container--open {
    opacity: 1;
    visibility: visible;
    transform: translateY(0%);
    transition: opacity 0.6s ease-in-out, visibility 0s linear 0s, transform 0.6s ease-in-out;
  }

  .react-autosuggest__suggestions-list {
    list-style: none;
    padding: 4px;
    margin: 4px;
    border-radius: 10px;
  }

  .react-autosuggest__section-container {
    padding: 10px;
  }

  .react-autosuggest__section-title {
    font-weight: bold;
    margin-bottom: 10px;
    color: #fff;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px;
    transition: background-color 0.6s ease-in-out;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #444;
    border-radius: 10px;
    transition: background-color 0.6s ease-in-out;
  }

  .search-input {
    position: relative;
  }

  .clear-input {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    font-size: 1rem;
    color: #ccc;
    cursor: pointer;
  }

  .clear-input:hover {
    color: #000;
  }
}
