.card-profile {
    p {
        margin: 0;
        padding: 0;
    }
    .left {
        display: flex;
        align-items: center;
        justify-content: left;
        p.title {
            text-transform: uppercase;
            font-weight: bold;
        }

        .left-bottom {
            display: flex;
            flex-direction: column;
            align-items: left;
            justify-content: center;
        }
    }
}
